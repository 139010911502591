import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import PageLoader from './components/Common/PageLoader'
import VueElementLoading from "vue-element-loading";
import ServerError from './components/Common/500'
Vue.component("VueElementLoading", VueElementLoading);
import { VueEditor } from "vue2-editor";
Vue.component('VueEditor', VueEditor)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
import CryptoJS from 'crypto-js'
Vue.use(CryptoJS)
Vue.component('apexchart', VueApexCharts)
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component(VueCropper);
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
Vue.component('PageLoader', PageLoader)
Vue.component('ServerError', ServerError)
Vue.config.productionTip = false

window.axios = require('axios')



window.axios = require('axios')

// server

axios.defaults.baseURL = 'https://apiibbooking.leopardtechlabs.in/';
Vue.prototype.baseURL = "https://apiibbooking.leopardtechlabs.in/"
Vue.prototype.mediaURL = "https://apiibbooking.leopardtechlabs.in/file/get?key="
Vue.prototype.mediaURLnew = "https://apiibbooking.leopardtechlabs.in/wp?key="     


// axios.defaults.baseURL = 'https://ibapi.forest.kerala.gov.in/';
// Vue.prototype.baseURL = "https://ibapi.forest.kerala.gov.in/"
// Vue.prototype.mediaURL = "https://ibapi.forest.kerala.gov.in/file/get?key="
// Vue.prototype.mediaURLnew = "https://ibapi.forest.kerala.gov.in/wp?key="   




//evin-->>
// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.54.130:4000'
// Vue.prototype.baseURL = "http://192.168.54.130:4000"
// Vue.prototype.mediaURL = "http://192.168.54.130/file/get?key="
// Vue.prototype.mediaURLnew = "http://192.168.54.130:4000/wp?key="
//sajan-->>

// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.137.222:4000/'
// Vue.prototype.baseURL = "http://192.137.222:4000/"
// Vue.prototype.mediaURL = "http://192.137.222/file/get?key="
// Vue.prototype.mediaURLnew = "http://192.137.222:4000/wp?key="

// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.52.85:4000'
// Vue.prototype.baseURL = "http://192.168.52.85:4000"
// Vue.prototype.mediaURL = "http://192.168.52.85/file/get?key="
// Vue.prototype.mediaURLnew = "http://192.168.52.85:4000/wp?key="




new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')